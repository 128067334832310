// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
@font-face {
  font-family: SNEmeric;
  src: url(./../fonts/SNEmeric-Light.ttf);
}
@font-face {
  font-family: "itcfranklingothicstd-demi";
  src: url(./../fonts/itcfranklingothicstd-demi.otf);
}

$font-family-base: SNEmeric;
$body-bg: #ffffff;
$body-color: #727272;

$primary: #82b7b2;
@import "../../../node_modules/bootstrap/scss/bootstrap";

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
);

// color theme ;
$main-bar: #EB633F;
$main-bar-hover: #EB633F;
$main-btn: #377497;
$main-btn-hover: #035886;
$pane-heading: #f59d15;


.new-req-btn {
  background-color: #EB633F;

  color: #727272;
}
.new-req-btn:hover {
  background-color: #fdc008;
}
.side-bar {
  width: 4.5rem;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: $main-bar;
  z-index: 2;
}
.side-bar a {
  padding: 10px;
}
@media screen and (max-width: 425px) {
  .side-bar {
    width: 2.5rem;
  }
  .side-bar a {
    padding: 0px;
  }
}

.side-bar a:hover {
  background: $main-bar-hover;
}
.bottom-controlls {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.top-bar {
  min-height: 80px;
  padding: 5px 1px 15px 65px;
  background: $main-bar;
text-align: center

}
.logo {
  height: 80px;
  display: inline-block;
  margin: 15px 15px 15px 15px;
}
.blobs {
  position: relative;

  pointer-events: none;
}
.blob-left {
  position: absolute;
  top: 0;
  left: 0;
}

.footer {
  position: relative;
  bottom: 0px;
  right: 0px;
  //   padding-top: 350px;
}
// .main-wrapper {
//   //   background: #f6f4e8;
//   //   color: #00709c;
//   //   padding-bottom: 30px;
// }
.panel {
  padding: 100px 0px;
}
.pane-heading {
  color: $pane-heading;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "itcfranklingothicstd-demi";
}
.questionTab {
  font-family: "itcfranklingothicstd-demi";
  font-weight: 800;
  // color: #fff;
}
.desc-heading {
  color: $pane-heading;
  margin: 0 0 5px;
  font-weight: 800;
}
.btn-primary {
  background-color: $main-btn;
  border: 0 none;
  color: white;
}

.btn-primary:hover {
  background-color: $main-btn-hover;
  border: 0 none;
  color: white;
}
.footer-logos {
  position: absolute;
  z-index: 10;

  left: 0;
  right: 0;
}
.counter-item {
  display: inline-block;
  padding: 10px;
  text-align: center;
}
.counter-item .days,
.counter-item .hours,
.counter-item .minutes,
.counter-item .seconds {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  /* background-color: #377497; */
  padding: 5px;
  /* margin-right: 25px; */
  display: block;
}
.counter-item .smalltext {
  text-align: center;
  font-size: 13px;
  /* background-color: #7ebd19; */
  display: block;
  padding: 0px;
  text-transform: uppercase;
}
